import zhLocale from 'element-ui/lib/locale/lang/en'
export default {
  lang: "中文",
  // 菜单
  menu: {
    zhuangtai:"状态：",
    zt_desc_a:"已连接到钱包",
    zt_desc_b:"未连接到钱包",
    xmjs:"项目介绍",
    qwzsl:"全网总算力",
    wdzc:"我的资产",
    wdtj:"社区",
    pdrc:"排队入场",
    dhsl:"申购算力",
    dhsl2:"申购算力2.0",
    zyjd:"质押节点",
    aqsj:"安全审计",
    xtgg:"系统公告",
    dhxyx:"申购幸运星",
    xyxjl:"幸运星记录",
    xyxwf:"幸运星玩法",
    yysz:"语言设置",
    jdphb:"节点排行榜",
    connect:"连接",
    connected:"已连接",
    zcxq_star:"Star资产详情",
    zcxq_zeed:"ZEED资产详情",
    yccsl:"已产出算力",
    dhsl_num:"申购数量",
    remainingAmount:"剩余额度：",
    convertibleQuantity:"可申购数量",
    quantityUsed:"需使用数量",
    balance:"余额：",
    availableComPower:"可获得算力",
    exchange:"申购",
    residualComPower:"剩余算力",
    totalAssets_zeed:"ZEED总资产",
    totalAssets_yeed:"YEED总资产",
    totalAssets_BNB:"BNB总资产",
    totalAssets_star:"STAR总资产",
    extracted:"已提取",
    extract:"提取",
    directPushAddress:"社区地址",
    ranking:"排名",
    address:"地址",
    computationalPower:"算力",
    cumulativeIncome:"累计收益Star",
    currentNodeRanking:"当前节点排名：",
    myComputingPower:"我的算力：",
    pledgeQuantity:"质押数量",
    tip:"提示：",
    tipRedeemed:"质押成功15日后可赎回",
    pledge:"质押",
    redemptionUsdtAssets:"赎回USDT质押资产",
    finalCalculationForce:"总算力",
    totalOutputStar:"总产量Star",
    starOutput:"每1000算力产出Star",
    rewardsStar:"每个节点奖励Star",
    todayOutputStar:"STAR今日总产出",
    networkDistribution_zeed:"全网分配star",
    enter:"进入",
    currencyQuantity :"每1000U算力产币数量",
    totalNodeRewards:"累计节点奖励",
    rewardsPerNode:"今日每个节点奖励",
    homeAdTitle:"欢迎来到银河之星",
    homeAdTitleDes:"连接您的web3钱包以继续",
    langChange:"En",
    communityName:"社区",
    communityAdress:"我的社区地址：",
    copyCont:"复制",
    relatedPull:"关联地扯",
    superiorLink:"请输入上级推荐链接",
    parsingFailed:"推荐人地址解析失败",
    authorizedFirst:"申购须先授权",
    grantAuthorization:"授权",
    authorized:"已授权",
    grantAuthorizationTip:"提示：申购须先授权ZEED和USDT",
    cashWitNodeReward:"提现节点奖励",
    nodePledge:"节点质押",
    releasePledge:"解除质押",
    recommendedReWith:"社区提现",
    computingPowerReward:"算力提现",
    noRecommenderBound:"没有绑定推荐人",
    exchangeQuantityTip:"申购数量不能小于",
    exchangeQuantityTip2:"申购数量不能大于",
    toBeExtracted:"待提取",
    nodeBeExtracted:"节点待提取",
    miningBeExtracted:"挖矿待提取",
    noBindingParameter:"请输入上级推荐地址",
    associateCommunityAd:"请关联上级社区地址",
    boundRecommender:"已存在绑定的推荐人",
    copySuc:"复制成功",
    notSupportCopying:"该浏览器不支持自动复制",
    totalOutputTodaySTAR:"今日总产出(star)",
    totalNetworkOutputSTAR:"全网已产出(star)",
    calcPowerExchange:"算力申购",
    extractionSucceeded:"操作成功",
    noMoreText:"没有更多了",
    loadMoreText:"点击加载更多",
    noDataText:"暂无数据",
    drawAPrize:"开奖：",
    upcomingLottery:"即将开奖",
    prizePool:"奖金池",
    luckyStarsThisRound:"本回合您有幸运星：",
    bonusShareNumber:"以相同的顺序命中中奖号码以赢取奖金份额。",
    currentWinnableBonuses:"当前可赢取奖金：",
    hitTheTop1:"命中前1位",
    hitTheTop2:"命中前2位",
    hitTheTop3:"命中前3位",
    hitTheTop4:"命中前4位",
    hitTheTop5:"命中前5位",
    hitTheTop6:"命中前6位",
    incinerate:"销毁",
    selectCurrency:"选择币种",
    luckyStarNumber:"幸运星号码",
    currency:"币种",
    sixDigits:"六位数字",
    quantityToBeUsed:"需使用数量",
    cancel:"取消",
    destructionPool:"销毁池",
    yourLuckyStarHit:"如果您幸运星上的数字以正确的顺序命中中奖号码，您将赢得奖池中的部分奖金。",
    simple:"简单！",
    dhxyxStep1:"价格在回合开始时确定，每张幸运星相当于在幸运星中存入0.01 STAR。",
    waitingLottery:"等待开奖",
    dhxyxStep2:"每天早上9点和晚上9点之间替换进行一次抽奖。",
    checkBonus:"检查奖金",
    dhxyxStep3:"回合结束后，进入“幸运星记录”检查您是否中奖！",
    winningConditions:"中奖条件",
    winningConditionsDesc:"幸运星上的数字必须以正确的顺序命中才能中奖。",
    winningConditionsExam:"这是一个开奖示例，有A、B两张幸运星。",
    luckyStarA:"·幸运星A：前3位和后2位命中，但第4位错误，所以此幸运星仅赢得了“命中前3位”奖。",
    luckyStarB:"·幸运星B：尽管命中最后5位数字，，但第1位数字是错误的，因此这张幸运星并未中奖。",
    bonusGroupRules:"奖金组不可“叠加”：如果您按顺序命中前3位数字，将只能赢得“命中3位”组中的奖金，而不会赢得“命中1位”和“命中2位”组中的奖金。",
    bonusFunds:"奖金资金",
    bonusSource:"每回合抽奖的奖金来自三个来源：",
    luckyStarExchange:"幸运星申购",
    injectPrizePool:"·申购者申购该回合幸运星所支付的幸运星将30%销毁，70%入回奖池。",
    accumulatedBonus:"滚存奖金",
    reassignDesc:"·在每一回合之后，如果其中一个奖金组中无人中奖，则该组中无人领取的幸运星将滚存到下一回合并在奖池中重新分配。",
    luckyStarInjection:"幸运星注入",
    totalAverageLuckyStar:"·在一周的时间里，会从奖金库中提取总计平均幸运星添加到抽奖回合中。此幸运星当然也包括在滚存中！",
    totalAverage:"总计平均：",
    hitCounting:"命中数字",
    awardPoolAllocation:"奖池分配",
    happyParadise:"开心乐园",
    BAB:"币安宝",
    toExchange:"去申购",
    unfinishedRoundsName:"未完成的回合",
    completedRoundsName:"已完成的回合",
    connectYourWallet:"连接您的钱包",
    starsThisRound:"查看本回合您的幸运星",
    connectWallet:"连接钱包",
    noExchanged:"本回合您还没有申购幸运星",
    history:"历史记录",
    allHistory:"所有历史记录",
    myNumber:"我的号码",
    prizeDescTip:"以相同的顺序命中中奖号码以赢取奖金份额。",
    prizeBeenDrawn:"已开奖",
    winningNumbers:"中奖号码",
    round:"回合",
    roundMemberTotal:"此回合的玩家总数：",
    winningLuckyStar:"中奖幸运星",
    each:"每个",
    roundTotalRewards:"本回合共计奖励：",
    receiveRewards:"领取奖励",
    notWinningPrize:"未中奖",
    systemRandomNumber:"生成随机数",
    systemLuckyTip:"请输入(0-9)的六位数字",
    systemLuckySucTip:"提交成功",
    day:"天",
    notStarted:"未开始",
    noWinningData:"没有中奖数据",
    start:"开始",
    stopBetting:"停止投注",
    acceptAward:"可以领奖",
    connectionSuc:"连接成功",
    pleaseInstall:"请安装",
    wallet:"钱包",
    viewWalletTutorial:"查看钱包教程",
    creditLow:"余额不足",
    insufficientAmount:"剩余额度不足",
    inTheLottery:"区块开奖中...",
    BNBOutputToday:"BNB今日产出",
    BNBOutputTomorrow:"BNB明日产出",
    starDestroyedToday:"STAR今日销毁",
    starCumulativeDes:"STAR累计销毁",
    jdpmcyzsl:"节点排名参与总数量：",
    homeSqTip:"购买STAR须先授权白名单",
    homeSqTipBtn:"授权白名单",
  },
  ...zhLocale
}
