import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Toast from "./components/common/Toast";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import mLottery from './utils/mLottery';
Vue.prototype.mLottery = mLottery

Vue.use(VueAwesomeSwiper);

// 定义插件对象
const ToastObj = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ToastConstructor = Vue.extend(Toast);
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new ToastConstructor();
    // console.log(instance)
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);

    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$toast = (msg, duration = 1500) => {
      instance.message = msg;
      instance.visible = true;
      setTimeout(() => {
        instance.visible = false;
      }, duration)
    }
  }
}
Vue.use(ToastObj);

Vue.config.productionTip = false
Vue.use(ElementUI);
// new Vue({
//   router,
//   store,
//   i18n,
//   render: h => h(App)
// }).$mount('#app')

window.vm = new Vue({
  render: h => h(App),
  i18n,
  router,
  store
}).$mount('#app')


//过滤数据，取小数点后5位
Vue.filter('numFormat', function (num) {
  let realVal = "";
  if (!isNaN(num) && num !== "") {
    if(num==0||num=="0"){
      realVal = num;
    }else{
      // 截取当前数据到小数点后5位,改变toFixed的值即可截取你想要的数值
      realVal = parseFloat(num).toFixed(5);
    }
  } else {
    realVal = "--";
  }
  return realVal;
})
//过滤数据，取整数
Vue.filter('integerFilter', function (num) {
  let integer = "";
  if (!isNaN(num) && num !== "") {
    // 去掉小数点后部分，保留整数部分
    integer = Math.trunc(num)
  } else {
    integer = "--";
  }
  return integer;
})

//过滤数据，取小数点后5位
Vue.filter('numFormat2', function (num) {
  
  let realVal = "";
  if (!isNaN(num) && num !== "") {
    if(num==0||num=="0"){
      realVal = num;
    }else{
      // 截取当前数据到小数点后5位,改变toFixed的值即可截取你想要的数值
      realVal = Math.floor((num) * 100000) / 100000
    }
  } else {
    realVal = "--";
  }
  console.log('numFormat2',num,realVal)
  return realVal;
})

//过滤数据，取小数点后2位
Vue.filter('numFormat3', function (num) {
  
  let realVal = "";
  if (!isNaN(num) && num !== "") {
    if(num==0||num=="0"){
      realVal = num;
    }else{
      // 截取当前数据到小数点后2位,改变toFixed的值即可截取你想要的数值
      realVal = Math.floor((num) * 100) / 100
    }
  } else {
    realVal = "--";
  }
  console.log('numFormat3',num,realVal)
  return realVal;
})

//过滤数据，取小数点后4位
Vue.filter('numFormat4', function (num) {
  
  let realVal = "";
  if (!isNaN(num) && num !== "") {
    if(num==0||num=="0"){
      realVal = num;
    }else{
      // 截取当前数据到小数点后4位,改变toFixed的值即可截取你想要的数值
      realVal = Math.floor((num) * 10000) / 10000
    }
  } else {
    realVal = "--";
  }
  console.log('numFormat4',num,realVal)
  return realVal;
})