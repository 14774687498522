<template>
    <div class="wrapper_dialog">
        <transition name="fade" mode="out-in" appear>
        <div class="relative space_between">
            <div class="container_left" @click="closeNavDialog"></div>
            <div class="container_right">
                <div class="top">
                    <a href="javascript:;" class="logo_r"><img src="../../../public/images/logo.png" alt=""></a>
                    <a href="javascript:;" class="close_r" @click="closeNavDialog"><img src="../../../public/images/icon_close.png" alt=""></a>
                </div>
                <div class="adress_box">
                  <div class="title">Address</div>
                  <div class="center_ad">
                    <div class="connected_ad" v-if="connectedStatus==1">
                      <el-row type="flex" class="row-bg" justify="space-between">
                        <el-col :span="21" class="desc">{{address}}</el-col>
                        <el-col :span="3" class="copy tr"><img src="../../../public/images/fizhi.png" alt=""></el-col>
                      </el-row>
                    </div>
                    <div class="not_connected_ad" v-if="connectedStatus==0">
                      <div class="line">- - - - - - </div>
                    </div>
                  </div>
                  <div class="status_ad" v-if="connectedStatus==1">{{$t('menu.zhuangtai')}}{{$t('menu.zt_desc_a')}}</div>
                  <div class="status_ad" v-if="connectedStatus==0">{{$t('menu.zhuangtai')}}{{$t('menu.zt_desc_b')}}</div>
                  <div class="connected_ad_btn" v-if="connectedStatus==0"><a href="javascript:;" @click="openDialog(connectedStatus,1)">{{$t('menu.connect')}}</a></div>
                </div>
                <div class="right_nav_list">
                  <!-- <a href="javascript:;">
                    <span class="list_l"><img src="../../../public/images/xiangmu.png" alt=""><i>项目介绍</i></span>
                    <img class="right_icon_s" src="../../../public/images/right.png" alt="">
                  </a> -->
                  <router-link v-for="(item,index) in routerList" :key="index" :to="{name:item.name,path:item.path}">
                    <span class="list_l"><img :src="item.icon" alt=""><i>{{$t("menu." +item.title)}}</i></span>
                    <img class="right_icon_s" src="../../../public/images/right.png" alt="">
                  </router-link>
                  <a href="https://www.certik.com/projects/Star" target="_blank">
                    <span class="list_l"><img src="../../../public/images/anquan.png" alt=""><i>{{$t('menu.aqsj')}}</i></span>
                    <img class="right_icon_s" src="../../../public/images/right.png" alt="">
                  </a>
                  <!-- <a href="javascript:;">
                    <span class="list_l"><img src="../../../public/images/gonggao.png" alt=""><i>系统公告</i></span>
                    <img class="right_icon_s" src="../../../public/images/right.png" alt="">
                  </a> -->
                  <a href="javascript:;" @click="changeLan">
                    <span class="list_l"><img src="../../../public/images/yuyan.png" alt=""><i>{{$t('menu.yysz')}}</i></span>
                    <span class="en_fs"><i>{{$t('menu.langChange')}}</i><img class="right_icon_s" src="../../../public/images/qiehuan.png" alt=""></span>
                  </a>
                </div>
            </div>
        </div>
        </transition>
        <div v-if="dialogShow"><Wallet-Dialog :id="0" @transfer="openDialog"></Wallet-Dialog></div>
    </div>
</template>

<script>
import '../../style/rightNav.css';
import WalletDialog from '../../components/dialog/WalletDialog.vue';
export default {
  name: 'RightNav',
  components:{WalletDialog},
  props:['conStatus'],
  computed: {
    normalizedSize: function () {
      return this.conStatus
    } 
  },
  watch:{
    connectedStatus(newVal){
      // newVal是新值，oldVal是旧值
      this.conStatus = newVal;
    }
  },
  data() {
    return {
      address:'',
      connectedStatus:this.conStatus, //0未连接  1已连接
      dialogShow:false,//是否弹出钱包窗口
      routerList:[
      // {
      //   name:'ProjectIntroduction',
      //   path:'/projectIntroduction',
      //   icon:require("../../../public/images/xiangmu.png"),
      //   title:'xmjs'
      // },
      {
        name:'TotalComputing',
        path:'/totalComputing',
        icon:require("../../../public/images/quanwangsuanli.png"),
        title:'qwzsl'
      },{
        name:'MyAssets',
        path:'/myAssets',
        icon:require("../../../public/images/zichan.png"),
        title:'wdzc'
      },{
        name:'MyRecommendation',
        path:'/myRecommendation',
        icon:require("../../../public/images/tuijian.png"),
        title:'wdtj'
      },{
        name:'ExchangePower',
        path:'/exchangePower',
        icon:require("../../../public/images/suanli_s.png"),
        title:'dhsl'
      },
      {
        name:'PurchasingHome',
        path:'/purchasingHome',
        icon:require("../../../public/images/suanli_s.png"),
        title:'dhsl2'
      },
      {
        name:'PledgeNode',
        path:'/pledgeNode',
        icon:require("../../../public/images/jiedian_s.png"),
        title:'zyjd'
      
      },{
        name:'BiAnBao',
        path:'/biAnBao',
        icon:require("../../../public/images/icon-bab.png"),
        title:'BAB'
      },{
        name:'ExchangeLuckyStars',
        path:'/exchangeLuckyStars',
        icon:require("../../../public/images/icon-leyuan.png"),
        title:'happyParadise'
      },
      // {
      //   name:'LineUAdmission',
      //   path:'/lineUAdmission',
      //   icon:require("../../../public/images/tuijian.png"),
      //   title:'pdrc'
      // }
      // {
      //   name:'SecurityAudit',
      //   path:'/securityAudit',
      //   icon:require("../../../public/images/anquan.png"),
      //   title:'aqsj'
      // },
      // {
      //   name:'SystemAnnouncement',
      //   path:'/systemAnnouncement',
      //   icon:require("../../../public/images/gonggao.png"),
      //   title:'xtgg'
      // }
      ]
    }
  },
  created:function(){
    this.address = localStorage.getItem('address')
    if(this.address){
      this.connectedStatus = 1
    }
  },
  methods: {
    closeNavDialog:function(){
       if(this.connectedStatus==0){
          this.$emit('transfer',0,2)//向父组件TopNav.vue传递的数据connectStatus=0,type=2
        }
        if(this.connectedStatus==1){
          this.$emit('transfer',1,2)//向父组件TopNav.vue传递的数据connectStatus=1,type=2
        }
    },
    openDialog(connectStatus,type){
      if(type==1){//type=1 打开钱包弹窗，type=2 关闭钱包弹窗
        this.dialogShow=true;
        console.log(connectStatus,'==',type);
      }
      if(type==2){
        this.dialogShow=false;
        this.connectedStatus = connectStatus;
        this.closeNavDialog();
      }
    },
    changeLan(){
      // console.log(this.$i18n,'=============');
      let command = this.$i18n.locale;
      if(command == 'en') {
          this.$i18n.locale = 'zh';
      } else if (command == 'zh') {
          this.$i18n.locale = 'en';
      }
    }
  },
}
</script>

<style>

</style>