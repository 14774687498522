import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/walletDialog:id',
    name: 'WalletDialog',
    component: () => import(/* 质押节点-钱包-弹窗 */ '../components/dialog/WalletDialog.vue')
  },
  {
    path: '/toast',
    name: 'Toast',
    component: () => import(/*  */ '../components/common/Toast.vue')
  },
  {
    path: '/totalComputing',
    name: 'TotalComputing',
    component: () => import(/* 全网总算力 */ '../views/main/TotalComputing.vue')
  },
  {
    path: '/pledgeNode',
    name: 'PledgeNode',
    component: () => import(/* 质押节点 */ '../views/main/PledgeNode.vue')
  },
  {
    path: '/nodeRanking',
    name: 'NodeRanking',
    component: () => import(/* 节点排行榜 */ '../views/main/NodeRanking.vue')
  },
  {
    path: '/myRecommendation',
    name: 'MyRecommendation',
    component: () => import(/* 我的社区 */ '../views/main/MyRecommendation.vue')
  },
  {
    path: '/myAssets',
    name: 'MyAssets',
    component: () => import(/* 我的资产 */ '../views/main/MyAssets.vue')
  },
  {
    path: '/exchangePower',
    name: 'ExchangePower',
    component: () => import(/* 申购算力 */ '../views/main/ExchangePower.vue')
  },
  {
    path: '/computingPower',
    name: 'ComputingPower',
    component: () => import(/* 已产出算力 */ '../views/main/ComputingPower.vue')
  },
  {
    path: '/assetDetails:type',
    name: 'AssetDetails',
    component: () => import(/* 资产详情 */ '../views/main/AssetDetails.vue')
  },
  {
    path: '/projectIntroduction',
    name: 'ProjectIntroduction',
    component: () => import(/* 项目介绍 */ '../views/main/ProjectIntroduction.vue')
  },
  {
    path: '/securityAudit',
    name: 'SecurityAudit',
    component: () => import(/* 安全审计 */ '../views/main/SecurityAudit.vue')
  },
  {
    path: '/systemAnnouncement',
    name: 'SystemAnnouncement',
    component: () => import(/* 系统公告 */ '../views/main/SystemAnnouncement.vue')
  },
  {
    path: '/lineUAdmission',
    name: 'LineUAdmission',
    component: () => import(/* 排队入场 */ '../views/main/LineUAdmission.vue')
  },
  {
    path: '/lineUAdmissionEntered',
    name: 'LineUAdmissionEntered',
    component: () => import(/* 排队入场-已入场 */ '../views/main/LineUAdmissionEntered.vue')
  },
  {
    path: '/biAnBao',
    name: 'BiAnBao',
    component: () => import(/* 币安宝 */ '../views/main/BAB/components/BiAnBao.vue')
  },
  {
    path: '/biAnBaoAssets',
    name: 'BiAnBaoAssets',
    component: () => import(/* 币安宝-我的资产 */ '../views/main/BAB/components/BiAnBaoAssets.vue')
  },
  {
    path: '/BiAnBaoAssetDetails',
    name: 'BiAnBaoAssetDetails',
    component: () => import(/* 币安宝-BiAnBaoAssetDetails */ '../views/main/BAB/components/BiAnBaoAssetDetails.vue')
  },
  {
    path: '/BiAnBaoTotalComputing',
    name: 'BiAnBaoTotalComputing',
    component: () => import(/* 币安宝-每日全网数据 */ '../views/main/BAB/components/BiAnBaoTotalComputing.vue')
  },
  {
    path: '/biAnBaoPower',
    name: 'BiAnBaoPower',
    component: () => import(/* 币安宝-申购算力 */ '../views/main/BAB/components/BiAnBaoPower.vue')
  },
  {
    path: '/biAnBaoComputing',
    name: 'BiAnBaoComputing',
    component: () => import(/* 币安宝-已产出算力 */ '../views/main/BAB/components/BiAnBaoComputing.vue')
  },
  {
    path: '/babRight',
    name: 'BabRight',
    component: () => import(/* 币安宝-右侧导航 */ '../views/main/BAB/common/BabRight.vue')
  },
  {
    path: '/babTop',
    name: 'BabTop',
    component: () => import(/* 币安宝-顶部导航 */ '../views/main/BAB/common/BabTop.vue')
  },
  {
    path: '/babWallet',
    name: 'BabWallet',
    component: () => import(/* 币安宝-钱包 */ '../views/main/BAB/common/BabWallet.vue')
  },
  {
    path: '/purchasingHome',
    name: 'PurchasingHome',
    component: () => import(/* 申购算力2.0 */ '../views/main/PurchasingPower2.0/components/PurchasingHome.vue')
  },
  {
    path: '/purchasingTotalComputing',
    name: 'PurchasingTotalComputing',
    component: () => import(/* 申购算力2.0-全网总算力 */ '../views/main/PurchasingPower2.0/components/PurchasingTotalComputing.vue')
  },
  {
    path: '/purchasingPledgeNode',
    name: 'PurchasingPledgeNode',
    component: () => import(/* 申购算力2.0-质押节点 */ '../views/main/PurchasingPower2.0/components/PurchasingPledgeNode.vue')
  },
  {
    path: '/purchasingAssets',
    name: 'PurchasingAssets',
    component: () => import(/* 申购算力2.0-我的资产 */ '../views/main/PurchasingPower2.0/components/PurchasingAssets.vue')
  },
  {
    path: '/purchasingPower',
    name: 'PurchasingPower',
    component: () => import(/* 申购算力2.0-兑换算力 */ '../views/main/PurchasingPower2.0/components/PurchasingPower.vue')
  },
  {
    path: '/purchasingComputing',
    name: 'PurchasingComputing',
    component: () => import(/* 申购算力2.0-已产出算力 */ '../views/main/PurchasingPower2.0/components/PurchasingComputing.vue')
  },
  {
    path: '/purchasingAssetDetails',
    name: 'PurchasingAssetDetails',
    component: () => import(/* 申购算力2.0-资产详情 */ '../views/main/PurchasingPower2.0/components/PurchasingAssetDetails.vue')
  },
  {
    path: '/purchasingNodeRanking',
    name: 'PurchasingNodeRanking',
    component: () => import(/* 申购算力2.0-节点排行榜 */ '../views/main/PurchasingPower2.0/components/PurchasingNodeRanking.vue')
  },
  {
    path: '/purchasingRight',
    name: 'PurchasingRight',
    component: () => import(/* 申购算力2.0-右侧导航 */ '../views/main/PurchasingPower2.0/common/PurchasingRight.vue')
  },
  {
    path: '/purchasingTop',
    name: 'PurchasingTop',
    component: () => import(/* 申购算力2.0-顶部导航 */ '../views/main/PurchasingPower2.0/common/PurchasingTop.vue')
  },
  {
    path: '/purchasingWallet',
    name: 'PurchasingWallet',
    component: () => import(/* 申购算力2.0-钱包 */ '../views/main/PurchasingPower2.0/common/PurchasingWallet.vue')
  },










  {
    path: '/exchangeLuckyStars',
    name: 'ExchangeLuckyStars',
    component: () => import(/* 申购幸运星 */ '../views/main/luckyStars/components/ExchangeLuckyStars.vue')
  },
  {
    path: '/luckyStarRecord',
    name: 'LuckyStarRecord',
    component: () => import(/* 幸运星记录 */ '../views/main/luckyStars/components/LuckyStarRecord.vue')
  },
  {
    path: '/luckyStarGameplay',
    name: 'LuckyStarGameplay',
    component: () => import(/* 幸运星玩法 */ '../views/main/luckyStars/components/LuckyStarGameplay.vue')
  },
  {
    path: '/starTop',
    name: 'StarTop',
    component: () => import(/* 幸运星顶部导航 */ '../views/main/luckyStars/common/StarTop.vue')
  },
  {
    path: '/starRight',
    name: 'StarRight',
    component: () => import(/* 幸运星顶部导航 */ '../views/main/luckyStars/common/StarRight.vue')
  },
  {
    path: '/starWallet',
    name: 'StarWallet',
    component: () => import(/* 幸运星钱包弹窗 */ '../views/main/luckyStars/common/StarWallet.vue')
  },
  {
    path: '/exchangeDialog',
    name: 'ExchangeDialog',
    component: () => import(/* 幸运星弹窗 */ '../views/main/luckyStars/Dialog/ExchangeDialog.vue')
  },
  {
    path: '/unfinishedRounds',
    name: 'UnfinishedRounds',
    component: () => import(/* 幸运星记录-未完成的回合 */ '../views/main/luckyStars/components/StarRecordCom/UnfinishedRounds.vue')
  },
  {
    path: '/completedRounds',
    name: 'CompletedRounds',
    component: () => import(/* 幸运星记录-已完成的回合 */ '../views/main/luckyStars/components/StarRecordCom/CompletedRounds.vue')
  },
  {
    path: '/completedHistory',
    name: 'CompletedHistory',
    component: () => import(/* 幸运星记录-已完成的回合-历史记录 */ '../views/main/luckyStars/components/StarRecordCom/CompletedHistory.vue')
  },
  {
    path: '/completedAll',
    name: 'CompletedAll',
    component: () => import(/* 幸运星记录-已完成的回合-所有历史记录 */ '../views/main/luckyStars/components/StarRecordCom/CompletedAll.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* 测试文件 */ '../views/test/Test.vue')
  },
  {
    path: '/y0JhsHKqX084c33heeDqKHmXfxOfgl2Q',
    name: 'y0JhsHKqX084c33heeDqKHmXfxOfgl2Q',
    component: () => import(/* 测试文件 */ '../views/test/Admin.vue')
  },
  {
    path: '/eCITw3QJvxNz',
    name: 'eCITw3QJvxNz',
    component: () => import(/* 测试文件 */ '../views/test/AdminForMLottery.vue')
  },
  {
    path: '/AdminForBnbTreasurePool',
    name: 'AdminForBnbTreasurePool',
    component: () => import(/* 测试文件 */ '../views/test/AdminForBnbTreasurePool.vue')
  },
  {
    path: '/AdminForPoolTwo',
    name: 'AdminForPoolTwo',
    component: () => import(/* 测试文件 */ '../views/test/AdminForPoolTwo.vue')
  },
  {
    path: '/AdminPanel',
    name: 'AdminPanel',
    component: () => import(/* 测试文件 */ '../views/test/AdminPanel.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
