<template>
  <div id="app">
    <router-view class="wrapper"/>
  </div>
</template>
<script>
import './style/main.css';
// const starPool = require('./utils/starPool')

export default {
  created:function(){
    // // 获取url上的推荐人地址
    // let ref = this.$route.query.ref
    // // let ref = '0x8d90D9D868729d54bA180bD77A7De2521219059C';
    // console.log('ref',ref)
    // if(ref && typeof ref != "undefined" && ref != 0){
    //   localStorage.setItem('ref', ref)
    // }

    
  },
  mounted:function(){
    // 监听钱包变化
    if (typeof window.ethereum !== 'undefined') {
      const provider = window.ethereum;
      provider.on("accountsChanged", (accounts) => {
        // 账号改变
        var address = accounts[0]
        console.log('账号改变',address)
        localStorage.setItem('address', address) //切换账号


      });
      provider.on("chainChanged", (chainId) => {
        // 链改变
        // window.location.reload()
        console.log('链改变',chainId)
      });
      provider.on("disconnect", (code, reason) => {
        // 断开连接
        console.log('断开连接',code,reason)
      });

      // 自动获取钱包地址
      provider.request({ method: 'eth_requestAccounts' }).then((res)=>{
        var address = res[0]
        console.log("当前钱包地址："+address)
        localStorage.setItem('address',address)


      })
    }
  }
}
</script>
<style scoped>

</style>
