<template>
<transition name="fadePage" mode="out-in" appear>
<div>
<div class="relative">
  <div class="body_img"><img src="../../../public/images/bacWrapper.jpg" alt=""></div>
  <div class="body_container">
    <div class="top_nav_home"><a href="javascript:;" @click="openNavDialog(connectedStatus,1)"><img src="../../../public/images/more.png" alt=""></a></div>
    <div class="title_home"><img src="../../../public/images/logo_big.png" alt=""></div>    
    <div class="title_home_t">{{$t('menu.homeAdTitle')}}</div>
    <div class="title_home_d">{{$t('menu.homeAdTitleDes')}}</div>
    <div class="btn_con">
      <router-link v-if="connectedStatus==1" :to="{name:'PledgeNode',path:'/pledgeNode'}">{{$t('menu.enter')}}</router-link>
      <a href="javascript:;" v-if="connectedStatus==0" @click="openDialog(connectedStatus,1)">{{$t('menu.connect')}}</a>
    </div>
<!--     
    <div class="p_btn_z">
      <div class="list_tip">{{$t('menu.homeSqTip')}}</div>
      <div class="list_btn"><button @click="homeSqTipBtnClick" :disabled="isSqDisabled" :class="{disabled_btn:isSqDisabled}">{{$t('menu.homeSqTipBtn')}}</button></div>
    </div> -->

    <div class="con_bac box1">
      <div class="box1_left"><img src="../../../public/images/star.png" alt=""></div>
      <div class="box1_right">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="12" class="w_wrap">
            <div class="fs20 ffamily_B">{{totalOutputToday}}</div>
            <div class="fs12">{{$t('menu.totalOutputTodaySTAR')}}</div>
          </el-col>
          <el-col :span="12" class="w_wrap">
            <div class="fs20 ffamily_B">{{totalNetworkOutputSTAR}}</div>
            <div class="fs12">{{$t('menu.totalNetworkOutputSTAR')}}</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="con_bac box2">
      <div class="pic_t"><img src="../../../public/images/suanli.png" alt=""></div>
      <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="12" class="w_wrap">
            <div class="fs20 ffamily_B">{{theWholeNetIsStrong | numFormat2}}</div>
            <div class="fs12">
              <router-link class="icon_r" :to="{name:'TotalComputing',path:'/totalComputing'}">
                <span>{{$t('menu.qwzsl')}}</span><img src="../../../public/images/right.png" alt="">
              </router-link>
            </div>
          </el-col>
          <el-col :span="12" class="w_wrap">
          <!-- 每1000U算力产币数量=STAR今日总产出/全网总算力*1000 -->
            <div class="fs20 ffamily_B">{{theNumberOfCoinsProducedPer1000U}}</div>
            <div class="fs12">{{$t('menu.currencyQuantity')}}</div>
          </el-col>
        </el-row>
    </div>
    <div class="con_bac box2">
      <div class="pic_t"><img src="../../../public/images/jiedian.png" alt=""></div>
      <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="12" class="w_wrap">
            <div class="fs20 ffamily_B">{{cumulativeNodeReward}}</div>
            <div class="fs12">{{$t('menu.totalNodeRewards')}}</div>
          </el-col>
          <el-col :span="12" class="w_wrap">
            <div class="fs20 ffamily_B">{{bonusPerNodeToday}}</div>
            <div class="fs12">{{$t('menu.rewardsPerNode')}}</div>
          </el-col>
        </el-row>
    </div>


  </div>
</div>
<Right-Nav v-if="isShowRight" :conStatus="connectedStatus" @transfer="openNavDialog"></Right-Nav>
<Wallet-Dialog v-if="dialogShow" :id="0" @transfer="openDialog"></Wallet-Dialog>
</div>
</transition>
</template>

<script>
import '../../style/index.css';
import RightNav from '../../components/common/RightNav.vue';
import WalletDialog from '../../components/dialog/WalletDialog.vue';

const starPool = require('../../utils/starPool')

export default {
  name: 'Home',
  components:{RightNav,WalletDialog},
  data() {
    return {
      formatAmountMethod:starPool.amountConversion,
      isShowRight:false,
      dialogShow:false,
      connectedStatus:0,  //0未连接，1已连接
      todayOutputStar:0,
      networkDistribution_zeed:0,
      qwzsl:0,
      currencyQuantity:0,//每1000U算力产币数量=STAR今日总产出/全网总算力*1000 formatAmountMethod(totalPowStarMint)/totalPow*1000
      totalNodeRewards:0,
      rewardsPerNode:0,
      // 全网算力
      totalPow:0,// 全网算力
      totalSwap:0,//全网质押总额
      totalPowStarMint:0,//STAR今日总产出
      totalNodeStarMint:0,//节点奖励总数量
      // 今日奖励数据
      nodeRewardToday:0,//今日产出的star
      powRewardToday:0,//节点产出的总数量

      totalNodes:0,//节点总数量
      // 首页数据
      totalOutputToday:0,//今日总产出 = _nodeRewardToday + _powRewardToday
      totalNetworkOutputSTAR:0,// 全网已产出(star) = _totalPowStarMint + _totalNodeStarMint
      theWholeNetIsStrong:0,// 全网总算力 = _totalPow
      theNumberOfCoinsProducedPer1000U:0,//每1000U算力产币数量 = _powRewardToday/全网总算力*1000
      cumulativeNodeReward:0,//累计节点奖励 = _totalNodeStarMint
      bonusPerNodeToday:0,//今日每个节点奖励 = _nodeRewardToday / 节点总数量
      isSqDisabled:false, //授权白名单的禁用状态
    }
  },
  watch: {
    // 计算：每1000U算力产币数量 = _powRewardToday/全网总算力*1000
    powRewardToday(){
      console.log(this.powRewardToday,this.totalPow,this.theWholeNetIsStrong,Math.floor(this.theWholeNetIsStrong))
      this.theNumberOfCoinsProducedPer1000U =Math.floor(this.theWholeNetIsStrong)==0?0: starPool.toDecimal( this.powRewardToday/this.theWholeNetIsStrong*1000) //每1000U算力产币数量 = _powRewardToday/全网总算力*1000
    },
    totalPow(){
      this.theNumberOfCoinsProducedPer1000U = Math.floor(this.theWholeNetIsStrong)==0?0:starPool.toDecimal(this.powRewardToday/this.theWholeNetIsStrong*1000) //每1000U算力产币数量 = _powRewardToday/全网总算力*1000
    },
    // 计算：今日每个节点奖励 = _nodeRewardToday / 节点总数量
    nodeRewardToday(){
      this.bonusPerNodeToday = this.totalNodes==0?0:starPool.toDecimal(this.nodeRewardToday / this.totalNodes) // 今日每个节点奖励 = _nodeRewardToday / 节点总数量
    },
    totalNodes(){
      this.bonusPerNodeToday = this.totalNodes==0?0:starPool.toDecimal(this.nodeRewardToday / this.totalNodes) // 今日每个节点奖励 = _nodeRewardToday / 节点总数量
    }

  },
  created:function(){
    // starPool.getWithdraw().then(res=>{console.log('挖出流水',res)})
    // starPool.getPowSummary().then(res=>{console.log('每日全网数据',res)})
    // starPool.getRefEvent().then(res=>{console.log('一级推荐人',res)})

    // starPool.getPastEvents().then(res=>{console.log('getPastEvents',res)})
    let adr  = localStorage.getItem('address');
    console.log(adr,'================');
    if(adr!==''&&adr!==null&&adr!==undefined){
      this.connectedStatus = 1;
    }else{
      this.connectedStatus = 0;
    }
    let that = this
    //全网算力
    starPool.poolInfo().then(res=>{
      that.totalPow = res._totalPow // 全网算力
      that.totalSwap = res._totalSwap // 全网质押总额
      that.totalPowStarMint = starPool.amountConversion(res._totalPowStarMint) // STAR今日总产出
      that.totalNodeStarMint = starPool.amountConversion(res._totalNodeStarMint) // 节点奖励总数量
      
      that.totalNetworkOutputSTAR = that.totalPowStarMint + that.totalNodeStarMint // 全网已产出(star) = _totalPowStarMint + _totalNodeStarMint
      that.theWholeNetIsStrong = res._totalPow // 全网总算力 = _totalPow
      that.cumulativeNodeReward = that.totalNodeStarMint // 累计节点奖励 = _totalNodeStarMint
    })

    // 今日奖励数据
    starPool.rewarTodayInfo().then(res=>{
      that.nodeRewardToday = starPool.amountConversion(res._nodeRewardToday)
      that.powRewardToday = starPool.amountConversion(res._powRewardToday)
      // console.log(that.nodeRewardToday,that.powRewardToday)
      that.totalOutputToday =that.nodeRewardToday + that.powRewardToday // 今日总产出 = _nodeRewardToday + _powRewardToday
    })

    //节点排行榜(获取 节点总数量)
    starPool.nodeRanking().then(res=>{ 
      that.totalNodes = res._accounts.length
      console.log('节点总数量',that.totalNodes)
    })

    //查看用户是否白名单
    starPool.whiteNames().then(res=>{ 
      that.isSqDisabled = res
    })
    
  },
  methods:{
    openNavDialog:function(connectStatus,type){
        if(type==1){//type=1 打开右边导航栏弹窗，type=2 关闭右边导航栏弹窗
            this.isShowRight=true;
        }
        if(type==2){
            this.isShowRight=false;
            this.connectedStatus = connectStatus;

        }
    },
    openDialog(connectStatus,type){
      if(type==1){//type=1 打开钱包弹窗，type=2 关闭钱包弹窗
        this.dialogShow=true;
        console.log(connectStatus,'==',type);
      }
      if(type==2){
        this.dialogShow=false;
        this.connectedStatus = connectStatus;
      }
    },
    // 授权白名单
    homeSqTipBtnClick(){
      let that = this
      that.isSqDisabled=true;
      starPool.setWhiteName().then(res=>{ 
        console.log(res)
        if(typeof res ==='undefined'){
          that.isSqDisabled = false
        }
      })
    }
  }
}
</script>
<style scoped>
</style>

