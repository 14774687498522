<template>
  <transition name="fade">
    <div v-show="visible">{{message}}</div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      message: ""
    }
  }
}
</script>

<style scoped>
div {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 5rem;
  line-height: 5rem;
  margin-top: -2.5rem;
  padding:0 1.5rem;
  color: #E7F3FC;
  background-color: #000;
  border-radius: 0.6rem;
  text-align: center;
  font-size: 1.4rem;
  transform: translate(-50%, -50%);
  z-index: 9999999;
}
/* vue动画过渡效果设置 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>