import enLocale from 'element-ui/lib/locale/lang/en'
export default {
  lang: "English",
  // 菜单
  menu: {
    zhuangtai:"E状态：",  //状态：
    zt_desc_a:"E已连接到钱包", //已连接到钱包
    zt_desc_b:"E未连接到钱包",   //未连接到钱包
    xmjs:"E项目介绍",  //项目介绍
    qwzsl:"E全网总算力",   //全网总算力
    wdzc:"E我的资产",    //我的资产
    wdtj:"E社区",  //我的社区
    pdrc:"E排队入场",  //排队入场
    dhsl:"E申购算力",  //申购算力
    dhsl2:"E申购算力2.0", //申购算力2.0
    zyjd:"E质押节点",   //质押节点
    aqsj:"E安全审计",  //安全审计
    xtgg:"E系统公告",  //系统公告
    dhxyx:"E申购幸运星",  //申购幸运星
    xyxjl:"E幸运星记录", //幸运星记录
    xyxwf:"E幸运星玩法",   //幸运星玩法
    yysz:"E语言设置",  //语言设置
    jdphb:"E节点排行榜", //节点排行榜
    connect:"Connect", //连接
    connected:"connected",   //已连接
    zcxq_star:"EStar资产详情", //Star资产详情
    zcxq_zeed:"EZEED资产详情", //ZEED资产详情
    yccsl:"E已产出算力", //已产出算力
    dhsl_num:"E申购数量",  //申购数量
    remainingAmount:"E剩余额度：",   //剩余额度：
    convertibleQuantity:"E可申购数量", //可申购数量
    quantityUsed:"E需使用数量",  //需使用数量
    balance:"E余额：", //余额：
    availableComPower:"E可获得算力", //可获得算力
    exchange:"E申购",
    residualComPower:"E剩余算力",  //剩余算力
    totalAssets_zeed:"EZEED总资产",  //ZEED总资产
    totalAssets_yeed:"EYEED总资产", //YEED总资产
    totalAssets_BNB:"E-BNB总资产", //BNB总资产
    totalAssets_star:"ESTAR总资产",  //STAR总资产
    extracted:"E已提取", //已提取
    extract:"E提取", //提取
    directPushAddress:"E社区地址", //社区地址
    ranking:"E排名", //排名
    address:"E地址", //地址
    computationalPower:"E算力",  //算力
    cumulativeIncome:"E累计收益Star",  //累计收益Star
    currentNodeRanking:"E当前节点排名：",  //当前节点排名：
    myComputingPower:"E我的算力：",  //我的算力：
    pledgeQuantity:"E质押数量",  //质押数量
    tip:"E提示：", //提示：
    tipRedeemed:"E质押成功15日后可赎回",  //质押成功15日后可赎回
    pledge:"E质押",  //质押
    redemptionUsdtAssets:"E赎回USDT质押资产",  //赎回USDT质押资产
    finalCalculationForce:"E总算力", //总算力
    totalOutputStar:"E总产量Star", //总产量Star
    starOutput:"E每1000算力产出Star",  //每1000算力产出Star
    rewardsStar:"E每个节点奖励Star", //每个节点奖励Star
    todayOutputStar:"ESTAR今日总产出", //STAR今日总产出
    networkDistribution_zeed:"E全网分配zeed",  //全网分配zeed
    enter:"E进入", //进入
    currencyQuantity :"E每1000U算力产币数量",  //每1000U算力产币数量
    totalNodeRewards:"E节点奖励总数量",  //节点奖励总数量
    rewardsPerNode:"E每个节点奖励数量", //每个节点奖励数量
    homeAdTitle:"Welcome to Star",
    homeAdTitleDes:"connect your web3 wallet to contine",
    langChange:"中",
    communityName:"E社区",  //社区
    communityAdress:"E我的社区地址：", //我的社区地址：
    copyCont:"copy",  //复制
    relatedPull:"E关联地扯",  //关联地扯
    superiorLink:"E请输入上级推荐链接", //请输入上级推荐链接
    parsingFailed:"E推荐人地址解析失败",  //推荐人地址解析失败
    authorizedFirst:"E申购须先授权",  //申购须先授权
    grantAuthorization:"E授权", //授权
    authorized:"E已授权", //已授权
    grantAuthorizationTip:"E提示：申购须先授权ZEED和USDT",  //提示：申购须先授权ZEED和USDT
    cashWitNodeReward:"E提现节点奖励",  //提现节点奖励
    nodePledge:"E节点质押", //节点质押
    releasePledge:"E解除质押",  //解除质押
    recommendedReWith:"E社区提现",  //社区提现
    computingPowerReward:"E算力提现", //算力提现
    noRecommenderBound:"E没有绑定推荐人", //没有绑定推荐人
    exchangeQuantityTip:"E申购数量不能小于0", //申购数量不能小于0
    toBeExtracted:"E待提取",  //待提取
    nodeBeExtracted:"E节点待提取",  //节点待提取
    miningBeExtracted:"E挖矿待提取",  //挖矿待提取
    noBindingParameter:"E没带ref参数就不绑定",  //没带ref参数就不绑定
    associateCommunityAd:"E请关联上级社区地址", //请关联上级社区地址
    boundRecommender:"E已存在绑定的推荐人", //已存在绑定的推荐人
    copySuc:"E复制成功",  //复制成功
    notSupportCopying:"E该浏览器不支持自动复制", //该浏览器不支持自动复制
    totalOutputTodaySTAR:"Total output today(star)",  //今日总产出(star)
    totalNetworkOutputSTAR:"Output of the whole network(star)",  //全网已产出(star)
    calcPowerExchange:"E算力申购", //算力申购
    extractionSucceeded:"E操作成功", //操作成功
    noMoreText:"E没有更多了", //没有更多了
    loadMoreText:"E点击加载更多", //点击加载更多
    noDataText:"E暂无数据",  //暂无数据
    drawAPrize:"E开奖：",  //开奖：
    upcomingLottery:"E即将开奖", //即将开奖
    prizePool:"E奖金池", //奖金池
    luckyStarsThisRound:"E本回合您有幸运星：", //本回合您有幸运星：
    bonusShareNumber:"E以相同的顺序命中中奖号码以赢取奖金份额。", //以相同的顺序命中中奖号码以赢取奖金份额。
    currentWinnableBonuses:"E当前可赢取奖金：", //当前可赢取奖金：
    hitTheTop1:"E命中前1位", //命中前1位
    hitTheTop2:"E命中前2位", //命中前2位
    hitTheTop3:"E命中前3位", //命中前3位
    hitTheTop4:"E命中前4位", //命中前4位
    hitTheTop5:"E命中前5位", //命中前5位
    hitTheTop6:"E命中前6位", //命中前6位
    incinerate:"E销毁", //销毁
    selectCurrency:"E选择币种", //选择币种
    luckyStarNumber:"E幸运星号码", //幸运星号码
    currency:"E币种", //币种
    sixDigits:"E六位数字", //六位数字
    quantityToBeUsed:"E需使用数量", //需使用数量
    cancel:"cancel", //取消
    destructionPool:"E销毁池", //销毁池
    yourLuckyStarHit:"E如果您幸运星上的数字以正确的顺序命中中奖号码，您将赢得奖池中的部分奖金。", //如果您幸运星上的数字以正确的顺序命中中奖号码，您将赢得奖池中的部分奖金。
    simple:"E简单！", //简单！
    dhxyxStep1:"E价格在回合开始时确定，每张幸运星相当于在幸运星中存入0.01 STAR。。", //价格在回合开始时确定，每张幸运星相当于在幸运星中存入5美元。
    waitingLottery:"E等待开奖",
    dhxyxStep2:"E每天早上9点和晚上9点之间替换进行一次抽奖。", //每天早上9点和晚上9点之间替换进行一次抽奖。
    checkBonus:"E检查奖金", //检查奖金
    dhxyxStep3:"E回合结束后，进入“幸运星记录”检查您是否中奖！", //回合结束后，进入“幸运星记录”检查您是否中奖！
    winningConditions:"E中奖条件", //中奖条件
    winningConditionsDesc:"E幸运星上的数字必须以正确的顺序命中才能中奖。", //幸运星上的数字必须以正确的顺序命中才能中奖。
    winningConditionsExam:"E这是一个开奖示例，有A、B两张幸运星。", //这是一个开奖示例，有A、B两张幸运星。
    luckyStarA:"·E幸运星A：前3位和后2位命中，但第4位错误，所以此幸运星仅赢得了“命中前3位”奖。", //·幸运星A：前3位和后2位命中，但第4位错误，所以此幸运星仅赢得了“命中前3位”奖。
    luckyStarB:"·E幸运星B：尽管命中最后5位数字，，但第1位数字是错误的，因此这张幸运星并未中奖。", //·幸运星B：尽管命中最后5位数字，，但第1位数字是错误的，因此这张幸运星并未中奖。
    bonusGroupRules:"E奖金组不可“叠加”：如果您按顺序命中前3位数字，将只能赢得“命中3位”组中的奖金，而不会赢得“命中1位”和“命中2位”组中的奖金。", //奖金组不可“叠加”：如果您按顺序命中前3位数字，将只能赢得“命中3位”组中的奖金，而不会赢得“命中1位”和“命中2位”组中的奖金。
    bonusFunds:"E奖金资金", //奖金资金
    bonusSource:"E每回合抽奖的奖金来自三个来源：", //每回合抽奖的奖金来自三个来源：
    luckyStarExchange:"E幸运星申购", //幸运星申购
    injectPrizePool:"·E申购者申购该回合幸运星所支付的幸运星将30%销毁，70%入回奖池。", //·申购者申购该回合幸运星所支付的幸运星将全部注入回奖池。
    accumulatedBonus:"E滚存奖金", //滚存奖金
    reassignDesc:"·E在每一回合之后，如果其中一个奖金组中无人中奖，则该组中无人领取的幸运星将滚存到下一回合并在奖池中重新分配。", //·在每一回合之后，如果其中一个奖金组中无人中奖，则该组中无人领取的幸运星将滚存到下一回合并在奖池中重新分配。
    luckyStarInjection:"E幸运星注入", //幸运星注入
    totalAverageLuckyStar:"·E在一周的时间里，会从奖金库中提取总计平均幸运星添加到抽奖回合中。此幸运星当然也包括在滚存中！", //·在一周的时间里，会从奖金库中提取总计平均幸运星添加到抽奖回合中。此幸运星当然也包括在滚存中！
    totalAverage:"E总计平均：", //总计平均：
    hitCounting:"E命中数字", //命中数字
    awardPoolAllocation:"E奖池分配", //奖池分配
    happyParadise:"E开心乐园", //开心乐园
    BAB:"E币安宝", //币安宝
    toExchange:"E去申购", //去申购
    unfinishedRoundsName:"E未完成的回合", //未完成的回合
    completedRoundsName:"E已完成的回合", //已完成的回合
    connectYourWallet:"E连接您的钱包", //连接您的钱包
    starsThisRound:"E查看本回合您的幸运星", //查看本回合您的幸运星
    connectWallet:"E连接钱包", //连接钱包
    noExchanged:"E本回合您还没有申购幸运星", //本回合您还没有申购幸运星
    history:"E历史记录", //历史记录
    allHistory:"E所有历史记录", //所有历史记录
    myNumber:"E我的号码", //我的号码
    prizeDescTip:"E以相同的顺序命中中奖号码以赢取奖金份额。", //以相同的顺序命中中奖号码以赢取奖金份额。
    prizeBeenDrawn:"E已开奖", //已开奖
    winningNumbers:"E中奖号码", //中奖号码
    round:"E回合", //回合
    roundMemberTotal:"E此回合的玩家总数：", //此回合的玩家总数：
    winningLuckyStar:"E中奖幸运星", //中奖幸运星
    each:"E每个", //中奖幸运星
    roundTotalRewards:"E本回合共计奖励：", //本回合共计奖励：
    receiveRewards:"E领取奖励", //领取奖励
    notWinningPrize:"E未中奖", //未中奖
    systemRandomNumber:"E生成随机数", //生成随机数
    systemLuckyTip:"E请输入(0-9)的六位数字", //请输入(0-9)的六位数字
    systemLuckySucTip:"E提交成功", //提交成功
    day:"E天", //天
    notStarted:"E未开始", //未开始
    noWinningData:"E没有中奖数据", //没有中奖数据
    start:"E开始", //开始
    stopBetting:"E停止投注", //停止投注
    acceptAward:"E可以领奖", //可以领奖
    connectionSuc:"E连接成功", //连接成功
    pleaseInstall:"E请安装", //请安装
    wallet:"E钱包", //钱包
    viewWalletTutorial:"E查看钱包教程", //查看钱包教程
    creditLow:"E余额不足", //余额不足
    insufficientAmount:"E剩余额度不足", //剩余额度不足
    inTheLottery:"E区块开奖中...",//区块开奖中
    BNBOutputToday:"E-BNB今日产出", //BNB今日产出
    BNBOutputTomorrow:"E-BNB明日产出", //BNB明日产出
    starDestroyedToday:"E-STAR今日销毁", //STAR今日销毁
    starCumulativeDes:"E-STAR累计销毁", //STAR累计销毁
    jdpmcyzsl:"E节点排名参与总数量：",//节点排名参与总数量：
    homeSqTip:"E购买STAR须先授权白名单",//购买STAR须先授权白名单
    homeSqTipBtn:"E授权白名单",//授权白名单
  }, 
  ...enLocale
}
