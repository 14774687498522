import Web3 from "web3";
let ethers = require("ethers");

const web3 = new Web3(window.ethereum);

/** STAT V2.0 START */

// 当前网络（主网，测试网）
let defaultChain = process.env.VUE_APP_DEFAULT_CHAIN;

// 主网
let starAddr = "0xc724e1373D4CE5bd515431bCF184147bd756EFf9";
let zeedAddr = "0xb2f53069e1555793481aafe639f8e274f4ec8435";
let yeedAddr = "";
let oracleAddr = "0x1fF9Bd9F18c3BFa3B3E1260Bf92338e6D6997e1f";

// =========================================================
let starLottery = "0xEc1c4900e4562Ee5B772BA48E92EB1Dd15D17088";
let zeedLottery = "0x18E5d7e63678E2404D3D8A6c7891239b90CC2234";
let yeedLottery = "0x6476A475e04Bb513538CC47ad294536Cdd1e63B4";

// ABI =================================
let MLotteryAbiJson = require("./abi/MLottery.json");
let MLotteryAbi = MLotteryAbiJson.abi;
let usdtAbi = require("./abi/usdt_api.json");
let oracleAbiJson = require("./abi/TestOracle.json");
let oracleAbi = oracleAbiJson.abi;
console.log("oracleAbi", oracleAbi);

// 测试网
if (defaultChain) {
  console.log("测试网===========");
  starAddr = "0xA16Ee707A54b88C9ad6f0B175d7602D9fcD13e44";
  zeedAddr = "0x36fDd5E6dEdA2dD2Cb19e37309FA056496787031";
  yeedAddr = "0x1268166fF610453506142BD8DE445c8877ba40cc";
  oracleAddr = "0xAaF03B8815dE32ac8A820cE5054082fC2D59013F";

  console.log("starAddr", starAddr);
  console.log("zeedAddr", zeedAddr);
  console.log("yeedAddr", yeedAddr);
  console.log("oracleAddr", oracleAddr);

  // =========================================================
  starLottery = "0x95cD0C34e6e35ABBBfF9433D77dD6fe9525dEbd5";
  zeedLottery = "0x510E5aAB1B1295B848F7a20b376C6Dc9f74357c4";
  yeedLottery = "0x3ca1dDC3128Db56D30A9e61ccD863a9a6A559e23";

  console.log("starLottery", starLottery);
  console.log("zeedLottery", zeedLottery);
  console.log("yeedLottery", yeedLottery);
}

let mLottery = {
  decimals: 18, // 小数位数
  // 合约
  contract: new web3.eth.Contract(MLotteryAbi, starLottery), // 合约MLottery（默认START合约）
  approveAddress: starAddr,
  approveContract: new web3.eth.Contract(usdtAbi, starAddr), // 合约授权（默认START合约）
  /**
   * 切换合约
   * @param {string} _lottery 0是STAR 1是ZEED 2是YEED
   */
  switchContract: function (_lottery) {
    //0是STAR 1是ZEED 2是YEED
    switch (_lottery) {
      case 0:
        this.contract = new web3.eth.Contract(MLotteryAbi, starLottery);
        this.approveAddress = starLottery;
        this.approveContract = new web3.eth.Contract(usdtAbi, starAddr);
        break;
      case 1:
        this.contract = new web3.eth.Contract(MLotteryAbi, zeedLottery);
        this.approveAddress = zeedLottery;
        this.approveContract = new web3.eth.Contract(usdtAbi, zeedAddr);
        break;
      case 2:
        this.contract = new web3.eth.Contract(MLotteryAbi, yeedLottery);
        this.approveAddress = yeedLottery;
        this.approveContract = new web3.eth.Contract(usdtAbi, yeedAddr);
        break;
    }
    console.log("切换合约", this.contract);
    return this;
  },

  /**
   * 当前正在进行的lotteryId
   */
  viewCurrentLotteryId() {
    return new Promise((resolve, reject) => {
      this.contract.methods.viewCurrentLotteryId().call((err, result) => {
        console.log("当前正在进行的lotteryId", result)
        resolve(result);
        if (err) reject(err);
      });
    });
  },

  /**
   * 根据lotteryId获得整一局的信息
   * @param {uint256} _lotteryId
   * @returns
   */
  viewLottery: function (_lotteryId) {
    return new Promise((resolve, reject) => {
      this.contract.methods.viewLottery(_lotteryId).call((err, result) => {
        console.log(
          "根据lotteryId获得整一局的信息",
          result,
          this.approveAddress
        );
        resolve(result);
        if (err) reject(err);
      });
    });
  },

  /**
   * 购买彩票
   * @param {uint256} _lotteryId    当局lotteryId
   * @param {uint32} _ticketNumber   6位彩票前加一个1, 如果054321 -> 1054321， 其中1是第一位，2是第二位。。。0是第6位，从后往前数
   * @returns
   */
  buyTickets: function (_lotteryId, _ticketNumber) {
    const address = localStorage.getItem("address"); //当前用户钱包地址
    _ticketNumber = this.formatTicketNumberForRequest(_ticketNumber)
    console.log("buyTickets param", _lotteryId, _ticketNumber);
    return new Promise((resolve, reject) => {
      this.contract.methods
        .buyTickets(_lotteryId, _ticketNumber)
        .send({ from: address }, function (err, result) {
          console.log("购买彩票", result)
          resolve(result);
          if (err) reject(err);
        });
    });
  },

  /**
   * 查看用户已购买彩票
   * @param {uint256*} _lotteryId
   * @returns
   */
  viewUserInfoForLotteryId: function (_lotteryId) {
    const _user = localStorage.getItem("address"); //用户地址
    return new Promise((resolve, reject) => {
      this.contract.methods
        .viewUserInfoForLotteryId(_user, _lotteryId)
        .call((err, result) => {
          console.log("查看用户已购买彩票", result)
          resolve(result);
          if (err) reject(err);
        });
    });
  },

  /**
   * 查看中奖信息
   * @param {uint256} _lotteryId
   * @param {uint256} _ticketId 彩票id
   * @param {uint32} _bracket 需要在前端判断中奖了多少位，0~5， 中奖一位传0，中奖6位传5
   * @returns
   */
  viewRewardsForTicketId: function (_lotteryId, _ticketId, _bracket) {
    return new Promise((resolve, reject) => {
      this.contract.methods
        .viewRewardsForTicketId(_lotteryId, _ticketId, _bracket)
        .call((err, result) => {
          console.log("查看中奖信息", result)
          resolve(result);
          if (err) reject(err);
        });
    });
  },

  /**
   * 领取奖励
   * @param {uint256} _lotteryId
   * @param {uint256[]} _ticketIds
   * @param {uint32[]} _brackets
   * @returns
   */
  claimTickets: function (_lotteryId, _ticketIds, _brackets) {
    const address = localStorage.getItem("address"); //当前用户钱包地址
    return new Promise((resolve, reject) => {
      this.contract.methods
        .claimTickets(_lotteryId, _ticketIds, _brackets)
        .send({ from: address }, function (err, result) {
          console.log("领取奖励", result)
          resolve(result);
          if (err) reject(err);
        });
    });
  },
  // 时间戳（毫秒）转化为标准时间格式
  formatTimeToType1: function (time) {
    if (time > 0) {
      var now = new Date(time * 1000);
      var nian = now.getFullYear();
      var yue = (now.getMonth() + 1).toString().padStart(2, "0");
      var ri = now.getDate().toString().padStart(2, "0");
      var shi = now.getHours().toString().padStart(2, "0");
      var fen = now.getMinutes().toString().padStart(2, "0");
      var miao = now.getSeconds().toString().padStart(2, "0");
      return `${nian}年${yue}月${ri}日 ${shi}:${fen}:${miao}`;
    }
    return "";
  },
  // 授权============================
  // 授权金额
  approveAmount: ethers.utils.parseUnits("9000000000000000000000000000", 18),
  /**
   * approve授权转账金额
   */
  approveTransferAmount: function () {
    const address = localStorage.getItem("address");
    console.log(
      "approveTransferAmount",
      address,
      this.approveAddress,
      this.approveAmount
    );
    console.log(this.approveContract);
    return new Promise((resolve, reject) => {
      this.approveContract.methods
        .approve(this.approveAddress, this.approveAmount.toString())
        .send({ from: address }, function (err, result) {
          console.log("approve授权", result)
          resolve(result);
          if (err) {
            reject(err);
          }
        });
    });
  },
  /**
   * 获取allowance授权金额
   * @returns
   */
  allowanceTransferAmount: function () {
    const address = localStorage.getItem("address");
    return new Promise((resolve, reject) => {
      this.approveContract.methods
        .allowance(address, this.approveAddress)
        .call({ from: address }, function (err, result) {
          console.log("获取allowance授权金额", result)
          resolve(result);
          if (err) {
            reject(err);
          }
        });
    });
  },
  /**
   * 获取token价格
   * @returns
   */
  getTokenPrice: function (_token) {
    let oracleContract = new web3.eth.Contract(oracleAbi, oracleAddr);
    return new Promise((resolve, reject) => {
      oracleContract.methods.getCurrentPrice(_token).call((err, result) => {
        let tokenPrice = (result / 10 ** 18).toString();
        console.log("获取" + _token + "价格", tokenPrice);
        resolve(tokenPrice);
        if (err) {
          reject(err);
        }
      });
    });
  },
  /**
   * 获取StarAddr价格
   * @returns
   */
  getStarAddrPrice: function () {
    return new Promise((resolve, reject) => {
      this.getTokenPrice(starAddr).then((res) => {
        resolve(res);
        if (!res) {
          reject(res);
        }
      });
    });
  },
  /**
   * 获取zeedAddr价格
   * @returns
   */
  getZeedAddrrPrice: function () {
    return new Promise((resolve, reject) => {
      this.getTokenPrice(zeedAddr).then((res) => {
        resolve(res);
        if (!res) {
          reject(res);
        }
      });
    });
  },
  /**
   * 获取Yeed价格
   * @returns
   */
  getYeedAddrPrice: function () {
    return new Promise((resolve, reject) => {
      this.getTokenPrice(yeedAddr).then((res) => {
        resolve(res);
        if (!res) {
          reject(res);
        }
      });
    });
  },
  /**
   * 金额转换，去掉18位小数
   */
  amountConversion: function (_amount) {
    // return Math.floor((_amount/usdtDecimal) * 100) / 100
    // 保留5位
    let newAmount = "";
    if (!isNaN(_amount) && _amount !== "") {
      if (_amount == 0 || _amount == "0") {
        newAmount = _amount;
      } else {
        // newAmount = parseFloat(_amount/usdtDecimal).toFixed(5);
        newAmount = Math.floor((_amount / (10 ** 18)) * 100000) / 100000;
      }
    } else {
      newAmount = "--";
    }
    return Number(newAmount);
  },
  /**
   * 获取彩票号码中奖的位数
   * //需要在前端判断中奖了多少位，0~5， 中奖一位传0，中奖6位传5
   * @param {*} _finalNumberList 
   * @param {*} _ticketNumber 
   */
  getTicketNumberBracket(_finalNumberList,_ticketNumber){
    let _bracket = -1;
    let _count = _finalNumberList.length
    // console.log(_count,_finalNumberList,_ticketNumber)
    for (let index = 0; index < _count; index++) {
      if (_finalNumberList[index] === _ticketNumber[index]) {
        _bracket++;
      }else{
        break
      }
    }
    return _bracket
  },
  /**
   * 格式化彩票号码【请求】
   * @param {*} _ticketNumber 
   */
  formatTicketNumberForRequest(_ticketNumber){
    let ticketNumber = _ticketNumber.split("").reverse().join("") // 字符串转数组，反转数组，数组转字符串
    return '1'+ ticketNumber // 前边加字符串1
  },
  /**
   * 格式化彩票号码【响应】
   * @param {*} _ticketNumber 
   */
  formatTicketNumberForResponse(_ticketNumber){
    let ticketNumber = _ticketNumber.slice(1).split("").reverse(); // 去掉第一位的1，字符串转数组，数组反转
    return ticketNumber
  }
};

/** STAT V2.0 END */

export default mLottery;
